/* Print Styles */
@media print {
    .notepad-page-menu-header,
    .notepad-post-menu-header-simple,
    .notepad-site-head-menu,
    .notepad-site-footer {
        display: none;
    }

    .notepad-resume {
      margin-top: 2rem;

      .notepad-page-header {
        display: none;
      }


    }

    html {
        font-size: 70%;
    }

    @page {
        margin: 3rem;
    }

    .resume-download {
        display: none;
    }
}